exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-achievements-jsx": () => import("./../../../src/pages/achievements.jsx" /* webpackChunkName: "component---src-pages-achievements-jsx" */),
  "component---src-pages-adventures-jsx": () => import("./../../../src/pages/adventures.jsx" /* webpackChunkName: "component---src-pages-adventures-jsx" */),
  "component---src-pages-bikes-jsx": () => import("./../../../src/pages/bikes.jsx" /* webpackChunkName: "component---src-pages-bikes-jsx" */),
  "component---src-pages-graduation-jsx": () => import("./../../../src/pages/graduation.jsx" /* webpackChunkName: "component---src-pages-graduation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-keyboards-jsx": () => import("./../../../src/pages/keyboards.jsx" /* webpackChunkName: "component---src-pages-keyboards-jsx" */),
  "component---src-pages-me-jsx": () => import("./../../../src/pages/me.jsx" /* webpackChunkName: "component---src-pages-me-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-watches-jsx": () => import("./../../../src/pages/watches.jsx" /* webpackChunkName: "component---src-pages-watches-jsx" */),
  "component---src-pages-workspaces-jsx": () => import("./../../../src/pages/workspaces.jsx" /* webpackChunkName: "component---src-pages-workspaces-jsx" */)
}

